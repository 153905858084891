<template>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 my-10">
<!--        <div class="rounded-md bg-white p-2 calendar">-->
<!--            <div id="calendar">-->

<!--            </div>-->
<!--        </div>-->

<!--        <br><br><br>-->

        <div class="rounded-md bg-white p-2">
            <full-calendar id="calendarVue" ref="calendar" :options="calendarOptions">
            </full-calendar>
        </div>



    </div>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    // import { Calendar } from '@fullcalendar/core';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import listPlugin from '@fullcalendar/list';
    import * as axios from '@/axios-auth'
    export default {
        name: "MyCalendar",
        components: {
            FullCalendar
        },
        data(){
            return {
                calendar: null,
                calendarVue: null,
                groupSessionsSource: null,
                calendarOptions: {
                    themeSystem: 'bootstrap',
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                    },
                    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
                    eventClick: this.eventClick
                }
            }
        },
        mounted() {
            this.initializeCalendar()
        },
        created() {
            this.fetchMyGroupSessions()
        },
        methods: {
            eventClick(info){
                console.log("event clicked", info.event)
                this.$router.push(`/groupSession/${info.event.id}`)
            },
            initializeCalendar(){
                this.calendarVue = this.$refs.calendar.getApi()
                // let calendarElement = document.querySelector("#calendar")
                // this.calendar = new Calendar(calendarElement, this.calendarOptions);
                // this.calendar.render();
            },
            fetchMyGroupSessions(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.get(`/v2/user/myGroupSessions`)
                .then((resp) => {
                    if(resp.status === 200 || resp.status === 201) {
                        let groupSessions = resp.data
                        groupSessions.forEach((groupSession) => {
                            groupSession.groupSession.title = groupSession.groupSession.topic
                            groupSession.groupSession.start = groupSession.groupSession.startsAt
                        })

                        let newSessions = groupSessions.map((groupSession) => {
                            groupSession.groupSession.title = groupSession.groupSession.topic
                            groupSession.groupSession.start = groupSession.groupSession.startsAt
                            groupSession.groupSession.id = groupSession.groupSession.JID
                            return groupSession.groupSession
                        })
                        const groupSessionsSource = {
                            events: newSessions,
                            color: 'blue',
                            // backgroundColor: 'green',
                            // borderColor: 'black',
                            textColor: 'white'
                        }
                        this.groupSessionsSource = groupSessionsSource
                        if(this.calendar !== null ) {
                            this.calendar.addEventSource(groupSessionsSource)
                        }
                        this.calendarVue.addEventSource(groupSessionsSource)


                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                    loader.hide()
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: searchCommunities FAILED", err)
                })
            }
        }
    }
</script>

<style>
    .fc-button {
        @apply bg-jiruto-zotSecondary border-white  px-5 !important
    }
    .fc-dayGridMonth-button {
        @apply rounded-l-full !important
    }
    .fc-listMonth-button {
        @apply rounded-r-full  !important
    }
    .fc-prev-button {
        @apply rounded-l-full !important
    }
    .fc-next-button {
        @apply rounded-r-full  !important
    }
    .fc-today-button {
        @apply rounded-full  !important
    }
    .fc-button-active {
        @apply bg-jiruto-zotDarker !important
    }
</style>
